import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    posts : {},
    main: {},
    about: {},
    contacts: {},
    maket: {},
    vacancy: {},
  },
  mutations: {
    setPosts(state, data){
      state.posts = data
    },
    setMain(state, data){
      state.main = data
    },
    setAbout(state, data){
      state.about = data
    },
    setContacts(state, data){
      state.contacts = data
    },
    setMaket(state, data){
      state.maket = data
    },
    setVacancy(state, data){
      state.vacancy = data
    }
  },
  actions: {

  },
  modules: {
  }
})
