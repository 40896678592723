<template>
<div class="main">
    <v-carousel v-if="main.acf.slajder[0]" cycle height="100vh" hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="(slide, i) in main.acf.slajder" :key="i">
            <div class="slide" :style="'background: url(\''+slide.izobrazhenie+'\') no-repeat center center / cover'">
                <div class="slide-overlay">
                    <div class="container">
                        <div class="slide-content">
                            <h2 class="h2-slider">{{slide.zagolovok_slajda}}</h2>
                            <p class="slide-description" v-html="slide.opisanie_slajda"></p>
                            <a :href="slide.ssylka" v-if="slide.ssylka" class="slide-btn">Подробнее</a>
                        </div>
                    </div>
                </div>

            </div>
        </v-carousel-item>
    </v-carousel>
    <section>
        <div class="container">
            <h3>Наши услуги</h3>
            <p>Широкий спектр возможностей!</p>
            <div class="services-wrapper">

                <div v-for="post in posts" :key="post.id" class="services-item" :style="'background: url(\''+post.acf.glavnoe_izobrazhenie+'\') no-repeat center center / cover'" @click="$router.push({ name: post.slug, params: {id: post.id, title: post.title.rendered, content: post.content.rendered, acf: post.acf}})">
                    <h3 class="service-title">{{post.title.rendered}}</h3>
                    <div class="services-overflow">
                        <h3 class="overflow-title">{{post.title.rendered}}</h3>
                        <div class="border"></div>
                        <p class="overflow-descr">{{post.acf.tekst_pod}}</p>
                        <button class="oveflow-btn">Подробнее</button>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <section>
        <div class="container">
            <h3>Наши приемущества</h3>
            <div class="pluses-wrapper">
                <div class="plus-item" v-for="(item, index) in main.acf.preimushhestva" :key="index">
                    <div class="plus-icon" :style="'background: url(\''+item.izobrazhenie+'\') no-repeat center center / contain'"></div>
                    <div class="plus-text">{{item.priemushhestvo}}</div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <h3>Благодарности</h3>

            <slider class="slide-c" v-bind="options" >
                <div v-for="(item, imageIndex) in main.acf.blagodarnosti" :key="item.izobrazhenie" class="item" :style="'background: url(\''+item.izobrazhenie+'\') no-repeat center center / cover'">
                    <div class="image-overlay" @click="index = imageIndex">
                            <v-icon>mdi-magnify-plus-outline</v-icon>
                        </div>
                </div>
            </slider>
            <CoolLightBox :items="pageGallery" :index="index" @close="index = null">
                </CoolLightBox>

        </div>
    </section>
    <contacts />
</div>
</template>

<script>
import Contacts from '../components/Contacts.vue'
import Slider from '../components/Slider.vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {
    components: { Contacts, Slider, CoolLightBox },

    data() {
        return {
            pageGallery: [],
            main: this.$store.state.main,
            posts: this.$store.state.posts,
            index: null,
            options: {
                items: 4,
                margin: 20,
                nav: true,
                dots: true,
                loop: true,
                timing: 'cubic-bezier(0, 0.72, 0.64, 1.06)',
                offset: 1,
                prevNav: '<',
                nextNav: '>',
                sibling: true,
                responsive:  {

            
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    999: {
                        items: 4
                    }
                }
            }

        }
    },
    created() {

        document.title = 'Рекламная фирма | UVprint'
        scrollTo(0, 0)

    },
    mounted() {
        for (let i=0; i<this.main.acf.blagodarnosti.length; i++){
            this.pageGallery.push(this.main.acf.blagodarnosti[i].izobrazhenie)
        }
    }

}
</script>

<style>
.image-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all .4s;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 40%), 0px 2px 2px 0px rgb(0 0 0 / 24%), 0px 1px 5px 0px rgb(0 0 0 / 22%);
    will-change: opacity;
}

.image-overlay:hover {
    opacity: 1;
}
.item{
    position: relative!important;
}
.v_slider{
    position: relative!important;
    margin-top: 30px;

}
.v_slider .v-icon{
    font-size: 35px;
}
.v_slider__prev, .v_slider__next{
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: rgba(0, 0, 0, 0.3);
    transition: all .2s
}
.v_slider__prev:hover, .v_slider__next:hover{
    background: rgba(255, 255, 255, 0.322);
}

.v_slider__prev{
    position: absolute;
    left: 10px;
    top: 180px;
    z-index: 2;
}
.v_slider__next{
    position: absolute;
    right: 10px;
    top: 180px;
    z-index: 2;
}
.v_slider__item{
    height: 400px;
}
.v_slider{
    width: 100%;
}
.pluses-wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.plus-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    flex: 1 1 200px;
    margin: 10px;
}

.plus-icon {
    height: 60px;
    width: 100%;
    transition: all .3s;
}

.plus-item:hover .plus-icon {
    transform: scale(1.1);
}

.plus-text {
    text-align: center;
    max-width: 200px;
    margin-top: 15px;
    transition: all .3s;
    font-size: 18px;
}

.plus-item:hover .plus-text {
    text-decoration: underline 1px #8452df;
}

.slide-content {
    align-self: baseline;
}


.oveflow-btn {
    margin-top: 25px;
    padding: 10px 25px;
    color: white;
    border: 1px solid white;
    transition: all .3s;
}

.oveflow-btn:hover {
    background: white;
    color: black;
}

.border {
    border-bottom: 2px solid white;
    width: 120px;
    border-radius: 0;
    margin: 10px 0;
    transform: translateY(300px);
    will-change: transform;
    transition: all .6s;
}

.overflow-descr {
    font-size: 16px;
    line-height: 14px;
    color: white;
    font-weight: 300;
    transform: translateY(-300px);
    will-change: transform;
    transition: all .6s;
    text-align: center;
}

.overflow-title {
    color: white;
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    transform: translateY(300px);
    margin-bottom: 0 !important;
    transition: all .6s;
    will-change: transform;
    text-transform: uppercase;

}

.service-title {
    bottom: 20%;
    position: absolute;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    padding: 0px 0;
    text-transform: uppercase;
    color: white;
    font-size: 18px;
    transition: all .5s;
    font-weight: 300;
    will-change: transform;

}

section {
    margin: 50px 0;
}

.services-overflow {
    opacity: 0;
    padding: 10px;
    top: 0;

    overflow: hidden;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .6s;
}

.services-item:hover .overflow-descr {
    transform: translateY(0);

}

.services-item:hover .overflow-title,
.services-item:hover .border {
    transform: translateY(0);

}

.services-item:hover .services-overflow {
    opacity: 1;
}

.services-item:hover .service-title {
    transform: translateY(200px);
}

.services-item:hover {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 40%), 0px 2px 2px 0px rgb(0 0 0 / 24%), 0px 1px 5px 0px rgb(0 0 0 / 22%);
}

.services-item {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    flex: 1 1 350px;
    height: 275px;
    margin: 15px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    max-width: 30%;

}

.services-wrapper {
    margin: 20px auto 0 auto !important;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.slide {
    width: 100%;
    height: 100%;
    color: black;
    position: relative;
}

.slide-overlay {
    position: absolute;
    top: 0%;
    right: 0%;
    left: 0%;
    bottom: 0;
    background: #0000007c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.h2-slider {
    color: white;
    font-size: 46px;
    font-weight: 600;
}

.slide-description {
    color: white;
    font-size: 18px;
    padding: 10px 0;

    margin-bottom: 22px;
    flex: 1 0 500px;
    max-width: 500px;
}

.slide-btn {
    color: white !important;
    border: 2px solid #8452df;
    background: #8452df;
    border-radius: 5px;
    padding: 10px 17px;
    font-weight: 500;
    text-decoration: none;
    transition: all .3s;

}

.slide-btn:hover {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 24%), 0px 1px 5px 0px rgb(0 0 0 / 22%);
}

.v-window__next {
    right: 0;
    left: auto;
}

h3 {
    font-size: 30px;
    text-align: center;
    color: #333333;

}
</style>
