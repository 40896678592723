<template>
<section>
    <div class="container contacts-sect">
        <div class="col-2">
            <p class="contacts-h">Остались вопросы?</p>
            <p class="contacts-p"> Свяжитесь с нами любым удобным способом, или заполните форму и мы перезвоним в течение 5 минут!</p>
            <a href="mailto:uvp721@yandex.ru" class="top-href">
                <v-icon>mdi-email-edit-outline</v-icon>
                uvp721@yandex.ru
            </a>
            <a href="tel:73452381063" class="top-href">
                <v-icon>mdi-phone-in-talk-outline</v-icon>
                +7 (3452) 38-10-63
            </a>

            <a href="https://vk.com/id221540605" class="top-href">
                <v-icon>mdi-vk</v-icon>
                Вконтакте
            </a>
            <a href="https://www.instagram.com/uv_print_tmn/" class="top-href">
                <v-icon>mdi-instagram</v-icon>
                Instagram
            </a>

        </div>
        <div class="col-2">
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-model="firstname" :rules="nameRules" :counter="40" label="Ваше имя" required></v-text-field>
                <v-text-field v-model="phone" :rules="phoneRules" :counter="11" label="Телефон" required></v-text-field>
                <v-text-field v-model="mail" :rules="mailRules" :counter="30" label="E-mail"></v-text-field>
                <v-btn :loading="loading" :disabled="!valid" class="mr-4" @click="sendData">
                    {{btnText}}
                </v-btn>
            </v-form>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios'
import constants from '../constants'
export default {
    data() {
        return {
            btnText: 'Отправить',
            valid: true,
            firstname: '',
            nameRules: [
                v => !!v || 'Имя обязательно!'
            ],
            mail: '',
            mailRules: [
                v => /.+@.+\..+/.test(v) || 'Укажите верный E-mail',
            ],
            phone: '',
            phoneRules: [
                v => !!v || 'Телефон обязателен!'
            ],
            loading: false
        }
    },
    methods: {
        sendData() {
            let data = new FormData;
            
            let dataForSend =
                `
            Заказан обратный звонок <br>
            Имя: ${this.firstname} <br>
            Почта: ${this.mail}<br>
            Телефон: ${this.phone}`
            data.append('data', dataForSend)
            if (this.$refs.form.validate()) {
                this.loading = true
                axios.post(constants.mailer, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {this.btnText = 'Отправлено'; this.valid=false; console.log(response); this.loading = false})
            }
        },
    },
}
</script>

<style>
.theme--light.v-btn.v-btn--has-bg {
    background-color: #8452df;
    color: white;
}
.contacts-h {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    color: #333333;
}

.contacts-p {
    text-align: center;
    margin: 10px 0;
}

.contacts-sect {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #f9f9f9;
    padding: 20px 0
}

.col-2 {
    display: flex;
    flex-direction: column;

    flex: 1 1 300px;
    padding: 20px;
}

.col-2 .top-href {
    align-self: center;

}
</style>
