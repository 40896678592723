<template>
<v-app>
    <div id="app">
        <loading :active.sync="loading" :is-full-page="fullPage" :opacity=1 loader=bars color="#8452df">

        </loading>
        <div class="social-icons-widget" @mouseover="stopAnim" @mouseout="playAnim">
            <a id="phone" href="tel:73452381063" class="widget-call"><img src="./assets/phone.svg"></a>
            <a id="vk" href="https://vk.com/im?media=&sel=221540605" class="widget-vk"><img src="./assets/vk.svg"></a>

        </div>
        <v-navigation-drawer v-model="drawer" app centered>
            <div class="burger">

                <v-list>
                    <v-list-item @click="$router.push({path: '/'})">
                        <v-list-item-title>Главная</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-for="post in posts" :key="post.id" @click="goRoute({ name: post.slug, params: {id: post.id, title: post.title.rendered, content: post.content.rendered, acf: post.acf}})">
                        <v-list-item-title>{{ post.title.rendered }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="goRoute({ path: 'o-kompanii'})">
                        <v-list-item-title>Мы - UVprint</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="goRoute({ path: 'contacts'})">
                        <v-list-item-title>Контакты</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="goRoute({ path: 'vacancy'})">
                        <v-list-item-title>Вакансии</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="goRoute({ path: 'maketreq'})">
                        <v-list-item-title>Требования к макету</v-list-item-title>
                    </v-list-item>
                </v-list>
                <div class="top-info">

                    <div class="contacts">
                        <a href="mailto:uvp721@yandex.ru" class="top-href">
                            <v-icon>mdi-email-edit-outline</v-icon>
                            uvp721@yandex.ru
                        </a>
                        <a href="tel:73452381063" class="top-href">
                            <v-icon>mdi-phone-in-talk-outline</v-icon>
                            +7 (3452) 38-10-63
                        </a>
                    </div>
                    <div class="social">
                        <a href="https://vk.com/id221540605" class="top-href">
                            <v-icon>mdi-vk</v-icon>
                        </a>
                        <a href="https://www.instagram.com/uv_print_tmn/" class="top-href">
                            <v-icon>mdi-instagram</v-icon>
                        </a>
                    </div>
                </div>
            </div>

        </v-navigation-drawer>
        <div id="nav">
            <div class="container">
                <div class="logo" @click="$router.push({path: '/'})"></div>
                <v-app-bar-nav-icon class="burger-btn" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <div class="menu">
                    <ul>
                        <li>
                            <router-link to="/">Главная</router-link>
                        </li>
                        <li>
                            <a href="#" @mouseover="openOnas">О нас</a>
                            <div @mouseleave="menuOnasOpened=0" class="submenu">
                                <v-list v-show="menuOnasOpened">
                                    <v-list-item @click="goRoute({ path: 'o-kompanii'})">
                                        <v-list-item-title>Мы - UVprint</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="goRoute({ path: 'contacts'})">
                                        <v-list-item-title>Контакты</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="goRoute({ path: 'vacancy'})">
                                        <v-list-item-title>Вакансии</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="goRoute({ path: 'maketreq'})">
                                        <v-list-item-title>Требования к макету</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </li>
                        <li>
                            <a href="#" @mouseover="openServices">Услуги</a>
                            <div @mouseleave="menuOpened=0" class="submenu">
                                <v-list v-show="menuOpened">
                                    <v-list-item v-for="post in posts" :key="post.id" @click="goRoute({ name: post.slug, params: {id: post.id, title: post.title.rendered, content: post.content.rendered, acf: post.acf}})">
                                        <v-list-item-title>{{ post.title.rendered }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </div>

                        </li>
                    </ul>

                </div>
                <div class="top-info">

                    <div class="contacts">
                        <a href="mailto:uvp721@yandex.ru" class="top-href">
                            <v-icon>mdi-email-edit-outline</v-icon>
                            uvp721@yandex.ru
                        </a>
                        <a href="tel:73452381063" class="top-href">
                            <v-icon>mdi-phone-in-talk-outline</v-icon>
                            +7 (3452) 38-10-63
                        </a>
                    </div>
                    <div class="social">
                        <a href="https://vk.com/id221540605" class="top-href">
                            <v-icon>mdi-vk</v-icon>
                        </a>
                        <a href="https://www.instagram.com/uv_print_tmn/" class="top-href">
                            <v-icon>mdi-instagram</v-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="route-v">
            <vue-page-transition name="fade-in-down">
                <router-view :key="$route.path" />
            </vue-page-transition>
        </div>

        <footer>
            <div class="container">
                <div class="bottom-menu">
                    <v-list-item class="bottom-menu-item" v-for="post in posts" :key="post.id" @click="$router.push({ name: post.slug, params: {id: post.id, title: post.title.rendered, content: post.content.rendered, acf: post.acf}})">
                        <v-list-item-title>{{ post.title.rendered }}</v-list-item-title>
                    </v-list-item>
                </div>
                <div class="top-info">

                    <div class="contacts">
                        <a href="mailto:uvp721@yandex.ru" class="top-href">
                            <v-icon>mdi-email-edit-outline</v-icon>
                            uvp721@yandex.ru
                        </a>
                        <a href="tel:73452381063" class="top-href">
                            <v-icon>mdi-phone-in-talk-outline</v-icon>
                            +7 (3452) 38-10-63
                        </a>
                    </div>
                    <div class="social">
                        <a href="https://vk.com/id221540605" class="top-href">
                            <v-icon>mdi-vk</v-icon>
                            Вконтакте
                        </a>
                        <a href="https://www.instagram.com/uv_print_tmn/" class="top-href">
                            <v-icon>mdi-instagram</v-icon>
                            Instagram
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</v-app>
</template>

<script>
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import constants from './constants'
export default {
    data() {
        return {
            posts: [],
            menuOpened: false,
            loading: true,
            fullPage: true,
            drawer: false,
            menuOnasOpened: false
        }
    },
    components: {
        Loading
    },
    methods: {
        stopAnim() {
            document.getElementById("phone").style.animation = "none";
            document.getElementById("vk").style.animation = "none"

        },
        playAnim() {
            document.getElementById("phone").style.animation = "hop 10s infinite";
            document.getElementById("vk").style.animation = "hop 10s infinite .4s"
        },
        goRoute(obj) {
            this.$router.push(obj);
            this.menuOpened = 0;
            this.menuOnasOpened = 0;
        },
        openServices() {
            this.menuOpened = 1;
            this.menuOnasOpened = 0;
        },
        openOnas() {
            this.menuOpened = 0;
            this.menuOnasOpened = 1;
        }
    },

    created() {
        axios.get(constants.vacancy).then(response => {
            this.$store.commit('setVacancy', response.data)
        })
        axios.get(constants.maketReq).then(response => {
            this.$store.commit('setMaket', response.data)
        })
        axios.get(constants.contacts).then(response => {
            this.$store.commit('setContacts', response.data)
        })
        axios.get(constants.main).then(response => {
            this.$store.commit('setMain', response.data)
        })
        axios.get(constants.about).then(response => this.$store.commit('setAbout', response.data.content.rendered))
        axios.get(constants.services).then(response => {
            this.posts = response.data;
            this.$store.commit('setPosts', response.data)
            let routes = [];
            for (let i = 0; i < this.posts.length; i++) {
                routes.push(

                    {
                        path: '/' + this.posts[i].slug,
                        name: this.posts[i].slug,
                        component: () => import( /* webpackChunkName: "posts" */ './views/Posts.vue'),
                        props: { id: this.posts[i].id, title: this.posts[i].title.rendered, content: this.posts[i].content.rendered, acf: this.posts[i].acf }
                    }
                );

            }
            routes.push({
                path: '/',
                name: 'Home',
                component: () => import( /* webpackChunkName: "posts" */ './views/Home.vue'),
            })
            routes.push({
                path: '/o-kompanii',
                name: 'About',
                component: () => import( /* webpackChunkName: "posts" */ './views/About.vue'),
            })
            routes.push({
                path: '/contacts',
                name: 'Contacts',
                component: () => import( /* webpackChunkName: "posts" */ './views/Contacts.vue'),
            })
            routes.push({
                path: '/maketreq',
                name: 'MaketReq',
                component: () => import( /* webpackChunkName: "posts" */ './views/MaketReq.vue'),
            })
            routes.push({
                path: '/vacancy',
                name: 'Vacancy',
                component: () => import( /* webpackChunkName: "posts" */ './views/Vacancy.vue'),
            })
            this.$router.addRoutes(routes)
            this.loading = false
        })
    },
}
</script>

<style>
footer .container {
    flex-direction: row;
}

.bottom-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 90px;
}

.bottom-menu-item {
    min-height: 30px !important;
}

.bottom-menu-item .v-list-item__title {
    color: white !important;
}

body,
.v-application {
    font-family: 'Montserrat', sans-serif !important;
}

footer {
    background: #adadad;
    padding: 20px;
}

footer .top-href {
    color: white !important;
}

.submenu {
    position: fixed;
    top: 60px;
    margin-left: -90px;
    z-index: 900;
    transition: all .4s;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.social-icons-widget {
    position: fixed;
    width: 150px;
    right: -70px;
    bottom: 20%;
    z-index: 9000;

}

.v-list-item:hover .v-list-item__title {
    color: #8452df;
}

.v-list-item__title {
    transition: all .3s;
}

.widget-call,
.widget-vk {
    background: #adadad;

    padding: 5px;
    height: 55px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    margin: 5px;
    cursor: pointer;
    animation: hop 10s infinite;
    transition: all 0.3s !important;
}

.widget-vk {
    animation-delay: .4s;
}

@keyframes hop {
    5% {
        transition: all 0.3s !important;
        background: #8452df;
        transform: translateX(-32px);
    }

    10% {
        transition: all 0.3s !important;
        background: #adadad;
        transform: translateX(0);
    }
}

.widget-call:hover,
.widget-vk:hover {
    background: #8452df;
    transform: translateX(-32px);
}

.menu ul {
    display: flex;

}

.menu li {
    list-style: none;
    padding: 0 30px;
}

.menu a {
    text-decoration: none;
    color: #333333 !important;
    font-size: 20px;
    font-weight: 400;
    transition: all .3s ease-out;

}

.menu a:hover {
    color: #8452df;
}

.top-info {
    display: flex;
}

.v-icon {
    color: #8452df !important;
}

.mdi-vk {
    color: #2787F5 !important;
}

.mdi-instagram {
    color: black !important;
}

.time-hours,
.contacts,
.social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
}

.top-href {
    text-decoration: none;
    color: #333333 !important;
    cursor: pointer;
    margin: 4px 0;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}

.logo {
    background: url('../public/logo.png') no-repeat center center / contain;
    height: 65px;
    width: 190px;
    margin-top: -5px;
}

#nav {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
    background: #f9f9f9;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 10%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    margin: 0 auto;
    flex-direction: column;
}

#nav .container {
    flex-direction: row;
}

.v-application ul {
    padding-left: auto !important;
}

.v-navigation-drawer {
    justify-content: center !important;
    align-items: center !important;
    z-index: 9000 !important;
    width: 100% !important;
    display: none !important;
}

.burger-li,
.burger-li a {
    list-style: none;
    color: #333333 !important;
    text-decoration: none;
}

.burger-btn {
    display: none !important;
}

.burger .v-list-item__title {
    text-align: center !important;
}

.assortiment {
    padding-left: 0 !important
}
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close), .v-navigation-drawer--temporary:not(.v-navigation-drawer--close){
    box-shadow: none!important;
}

@media (max-width: 1200px) {
    .container {
        padding: 10px 20px !important;
    }

    .bottom-menu {
        max-height: 100px;
    }

    #nav .time-hours {
        display: none;
    }

    .ass-item {
        max-width: 100% !important;
    }

    .plus-item {
        flex: 1 1 140px !important;
    }

    .services-item {
        max-width: 100% !important;
    }

}

@media (max-width: 1030px) {
    .bottom-menu {
        max-height: 140px;
    }
}

@media (max-width: 900px) {
    .menu {
        display: none;
    }

    .burger-btn {
        display: block !important;
    }

    .bottom-menu {
        max-height: 100%;
    }

    .v-navigation-drawer {
        display: flex !important;
    }
}

@media (max-width: 700px) {
    .top-info {
        flex-wrap: wrap;
        justify-content: center;
    }

    section {
        margin: 20px 0 !important
    }

    footer .container {
        flex-direction: column !important
    }

    .bottom-menu {
        align-items: center;
    }

    #nav .top-info {
        display: none;
    }
}

@media (max-width: 480px) {
    .sotr-img{
        height: 80px!important;
        width: 80px!important;
    }
    .top-href {
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .post-image {
        height: 250px !important;
    }

    .container {
        padding: 10px 5px !important;
    }

    .col-2 {
        padding: 0 !important;
        margin: 0 !important
    }

    section {
        margin: 10px 0 !important;
    }

    .image {
        max-width: 100% !important;
    }

    .h2-slider {
        font-size: 30px !important;
    }

    .slide-description {
        font-size: 16px !important;
    }

    .widget-call,
    .widget-vk {
        height: 40px;
    }

    .widget-call img,
    .widget-vk img {
        height: 30px;
    }

    .social-icons-widget {
        right: -100px;
    }

    .wp-block-image {
        height: 250px !important;
    }

    .post-title {
        font-size: 30px !important;
    }

    .opros-title {
        padding: 20px !important;
    }

    .logo {
        height: 40px !important;
    }

    .post-overlay {
        margin-top: 50px !important
    }

    .pop-up-holder {
        padding: 10px !important;
        width: 100% !important;
    }
}
</style>
